import React from 'react';

import Image from '../../components/Image/Image';
import Pattern from '../../components/Pattern/Pattern';
import TitleUnderlineParagraph from '../../components/TitleUnderlineParagraph/TitleUnderlineParagraph';
import { cn } from '../../utils';
import * as styles from './AlternatingGallery.module.scss';
import IAlternatingGallery from './AlternatingGallery.type';

const AlternatingGallery = (props: IAlternatingGallery) => (
    <section>
        {props.items.map((item) => (
            <div key={item.heading} className={cn(styles.item)}>
                <div className={cn(styles.container, "container")}>
                    <div className={styles.inner}>
                        <TitleUnderlineParagraph
                            className={styles.content}
                            heading={item.heading}
                            headingLevel="h2"
                            text={item.text}
                            textSize="medium"
                            underline="left"
                        />
                        <div className={styles.imageWrapper}>
                            <Pattern className={styles.pattern} />
                            <Image
                                className={styles.image}
                                {...item.image}
                                sizes="(min-width: 768px) 705px, 100vw"
                            />
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </section>
);

export default AlternatingGallery;
