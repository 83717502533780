import React from 'react';

import PatternSrc from '../../images/pattern.png';
import { cn } from '../../utils';
import * as styles from './Pattern.module.scss';
import IPattern from './Pattern.type';

const Pattern = ({ className }: IPattern) => (
    <div
        className={cn(styles.element, className)}
        style={{ backgroundImage: `url(${PatternSrc})` }}
    />
);

export default Pattern;
